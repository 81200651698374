import {
   ActivatedRouteSnapshot,
   CanActivate,
   Router,
   RouterStateSnapshot,
} from '@angular/router';

import { Injectable } from '@angular/core';

import { PermissoesService } from './../components/permissoes/permissoes.service';

@Injectable({providedIn: "root"})
export class PermissoesGuard implements CanActivate {
   userType = sessionStorage.getItem('userLevel');

   constructor(
      private permService: PermissoesService,
      private router: Router
   ) {}

   async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      if(this.userType != 'Bearer' ){
         let perms = route.data.perms
         const checked = await this.permService.check(perms)
         if(checked != null){
            !checked.success ? this.router.navigate(['/access-denid']) : null
            return checked.success ? true : false
         }else{
            return true
         }
      }
      return true
   }
}

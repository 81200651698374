import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class ContaService {

   private readonly API = `${environment.api}/contas`

   constructor(
      private http: HttpClient
   ){}

   async select(): Promise<any>{
      return await this.http.get<any[]>(this.API).toPromise()
   }

   async selectBy(obj: any): Promise<any>{
      return await this.http.post<any>(this.API+"/edit", obj).toPromise()
   }

   async create(values: any): Promise<any>{
      return await this.http.post<any>(this.API+"/create", values).toPromise()
   }

   async update(values: any): Promise<any>{
      return await this.http.put<any>(this.API+"/update", values).toPromise()
   }

   async delete(values: any): Promise<any>{
      return await this.http.post<any>(this.API+"/delete", {
         ids: values
      }).toPromise()
   }
}

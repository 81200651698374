import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class SettingsService {
   private readonly API = `${environment.api}/settings`;

   constructor(private http: HttpClient) {}

   async select(): Promise<any> {
      return await this.http.get<any[]>(this.API).toPromise();
   }

   async update(values: any): Promise<any> {
      return await this.http.put<any>(this.API+'/update', values).toPromise();
   }
}

import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoriasService {
  private readonly API = `${environment.api}/categorias`;
  userType = sessionStorage.getItem('userLevel');
  userId = sessionStorage.getItem('userId');

  constructor(private http: HttpClient, private app: AppService) {}

  async select(): Promise<any> {
    const res = [];
    const result = await this.http.get<any[]>(this.API).toPromise();

    if (this.userType != this.app.typeUser) {
      return result;
    }

    if (this.userType == this.app.typeUser) {
      result.forEach((elem) => {
        if (parseInt(elem.conta_id) == parseInt(this.userId)) {
          res.push(elem);
        }
      });
      return res;
    }
  }

  async listCategorias(): Promise<any[]> {
    return await this.http.get<any[]>(this.API).toPromise();
  }

  async selectBy(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/edit', values).toPromise();
  }

  async create(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/create', values).toPromise();
  }

  async update(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/update', values).toPromise();
  }

  async delete(values: any): Promise<any> {
    return await this.http
      .post<any>(this.API + '/delete', {
        ids: values,
      })
      .toPromise();
  }
}
